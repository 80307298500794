
const viewRole = (role) => {
  switch (role) {
    case 'junior-manager':
    case 'manager':
    case 'senior-manager':
    case 'trainee-salesman': return 'shop'
    case 'storekeeper': return 'warehouse'
    case 'courier':return 'courier'
    default: return 'none'
  }
}

export { viewRole }
