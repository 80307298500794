import { role } from '@/controlles/auth'
import { bLock } from '@/components/blocks'

/*
 junior-manager - младший менеджер
 courier - курьер
 manager - менеджер
 senior-manager - старший менеджер
 storekeeper - кладовщик
 courier-delivery-dispatcher - диспетчер курьерский доставко
 trainee-salesman - продавец стажер
 sponsorship - спонсорство

*/

const getMenuHeader = () => {
  const menuheader = [
    { name: 'Главная', role: ['junior-manager', 'courier'], path: 'home' },
    { name: 'Мой профиль', role: ['junior-manager', 'courier'], path: 'user-profile' },
    { name: 'Каталог', role: ['junior-manager'], path: 'catalog-index' },
    { name: 'Товары со скидками', role: ['junior-manager'], path: 'catalog-sale' },
    { name: 'Карта', role: ['courier'], path: 'couier-map' }
  ]
  return menuheader.filter(x => x.role.indexOf(role.value) !== -1)
}

const getMenuIndex = () => {
  const menuindex = [
    { name: 'Оформить заказ', role: ['junior-manager'], path: 'client-auth', class: 'button_light', query: {} },
    { name: 'Оформить возврат', role: ['junior-manager'], path: 'client-auth', class: 'button_light', query: { type: 'return' } },
    { name: 'Выдать', role: ['junior-manager'], class: 'button_light', okno: true, component: bLock().vydatOrderShop },
    { name: 'Собрать', role: ['storekeeper'], path: 'warehouse-collect', class: 'button_light', query: {} },
    { name: 'К отгрузке', role: ['storekeeper'], path: 'warehouse-issue', class: 'button_light', query: {} },
    { name: 'Возвраты', role: ['storekeeper'], path: 'shop', class: 'button_light', query: {} },
    { name: 'Перемещения', role: ['storekeeper'], path: 'shop', class: 'button_light', query: {} },
    { name: 'Поиск заказов', role: ['courier','storekeeper'], class: 'button_light', path: 'order-search', okno: true, component: bLock().searchOrder },
    { name: 'К доставке', role: ['courier'], path: 'delivery', class: 'button_light', query: {} },
    { name: 'На доставку', role: ['courier'], path: 'delivery', class: 'button_light', query: {} },
    { name: 'Доставить', role: ['courier'], path: 'delivery', class: 'button_light', query: {} },
    { name: 'Отчет', role: ['courier'], path: 'user-report', class: 'button_light', query: {} }
  ]
  return menuindex.filter(x => x.role.indexOf(role.value) !== -1)
}

const getMenuClient = () => {
  const menuclient = [
    { name: 'Профиль', role: ['junior-manager'], path: 'clientProfile' },
    { name: 'Заказы', role: ['junior-manager'], path: 'clientOrders' },
    { name: 'Заметки', role: ['junior-manager'], path: 'clientNotes' },
    { name: 'Клубы', role: ['junior-manager'], path: 'clientClubs' }
  ]
  return menuclient.filter(x => x.role.indexOf(role.value) !== -1)
}

export { getMenuHeader, getMenuIndex, getMenuClient }
