<template>
        <transition name="slimetop">
        <div class="suc"  v-if="suc.view">
             <div class="blockInfos">
                <svg v-if="suc.type == 'good'" width="118" height="118" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M116.143 53.7758V59.033C116.136 71.3554 112.146 83.3454 104.768 93.2148C97.3896 103.084 87.0188 110.304 75.2021 113.798C63.3853 117.292 50.7558 116.872 39.197 112.602C27.6382 108.332 17.7695 100.439 11.0627 90.1019C4.35584 79.7646 1.17027 67.5362 1.98105 55.2405C2.79182 42.9448 7.5555 31.2406 15.5616 21.8734C23.5678 12.5062 34.3874 5.97803 46.4068 3.26239C58.4263 0.546756 71.0016 1.7892 82.2572 6.80442M116.143 13.3188L59 70.5188L41.8572 53.3759" stroke="#00C48C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg v-if="suc.type == 'error'" width="118" height="118" viewBox="0 0 118 118" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M76.1429 41.8574L41.8572 76.1431M41.8572 41.8574L76.1429 76.1431M116.143 59.0003C116.143 90.5594 90.5592 116.143 59 116.143C27.4409 116.143 1.85718 90.5594 1.85718 59.0003C1.85718 27.4411 27.4409 1.85742 59 1.85742C90.5592 1.85742 116.143 27.4411 116.143 59.0003Z" stroke="#FF647C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                 </svg>

                 <p>{{ suc.text }}</p>
                 <div class="button clickbutton" @click="close()">Закрыть</div>
             </div>
        </div>
        </transition>
</template>

<script setup>
import { suc } from '@/controlles/general'

const close = () => {
  suc.value.text = ''
  suc.value.type = 'good'
  suc.value.view = false
}

</script>

<style scoped>
.suc{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: var(--white);
    display: grid;
    align-items: center;
    justify-items: center;
}

.blockInfos{

}

.blockInfos p {
    font-weight: 300;
    font-size: 18px;
    margin: 10px 0px 40px;
}
</style>
