import { ref, reactive, computed, watch } from 'vue'
import { client, activity } from '@/controlles/client'
import { notSet } from '@/controlles/general'
import axios from '@/utils/axios'
import { user } from '@/controlles/auth'
import { prototypeItem } from '@/controlles/basket/itemPrototype'

const basket = reactive([])
const viewbasket = ref(false)
const order = reactive({
  nextorder: [],
  activity: false,
  markingorder: false,
  producttotal: computed(() => {
    let price = 0
    order.nextorder = []
    order.markingorder = false
    for (const one of basket) {
      price = price + one.price * one.quantity
      order.nextorder.push(one.nextorder)
      if (one.marking) { order.markingorder = true }
    }
    return price
  }),
  typepayment: 'bank-card',
  paymentorder: {
    payment: computed(() => {
      let price = 0
      for (const one of basket) {
        price = price + one.price * one.quantity
      }
      return price - order.avanse - order.ad_deposit
    }),
    type: [
      { type: 'bank-card', activity: false, price: 0, entity: 'podgornov', name: 'Оплата картой (Терминал 1)' },
      { type: 'cash', activity: false, price: 0, entity: 'podgornov', name: 'Оплата наличными' },
      { type: 'bank-card', activity: false, price: 0, entity: 'damanina', name: 'Оплата картой (Терминал 2)' },
      { type: 'cash', activity: false, price: 0, entity: 'damanina', name: 'Оплата наличными' }
    ],
    paymentFull: 0
  },
  bonus: 0,
  avanse: 0,
  ad_deposit: 0,
  refferal:null,
  promocodename:null,
  promocode:null,
  nextordergo: computed(() => {
    for (const one of order.nextorder) {
      if (!one) { return false }
    }
    return true
  })
})

watch(order.paymentorder.type, (val) => {
  for (const one of order.paymentorder.type) {
    if (one.price > 0) {
      one.activity = true
      if (one.price < 0) {
        one.price = 0
      }
    } else { one.activity = false }
  }
})

const removePayment = () => {
  for (const one of order.paymentorder.type) {
    one.activity = false
    one.price = 0
  }
}

const addBasket = (product) => {
  if (!activity.value) {
    notSet('Авторизуйте клиента')
    return
  }
  const ins = basket.findIndex(x => x.productid == product.productid.value && x.specification == product.specification.value && x.timeadd !== product.timeadd)
  if (ins == -1) {
    basket.push(product)
  //  removePayment()
    notSet('Товар добавлен в корзину')
  } else { notSet('Данный товар уже добавлен') }
}

const deleteProduct = (i) => {
  basket.splice(i, 1)
//  removePayment()
}

const deleteBasket = () => {
  basket.splice(0)
  order.refferal = null
}

const changeBonus = (bonus) => {
  if (bonus == 0) {
    for (const one of basket) {
      one.remove_bonus = 0
    }
  } else {
    let allprice = 0
    const onepricent = Number(bonus) / 100
    for (const one of basket) { allprice = allprice + one.price * one.quantity }

    for (const one of basket) {
      const procent = (one.price / allprice) * 100
      const summa = Math.round(onepricent * procent)
      one.remove_bonus = summa
    }
  }
  order.bonus = bonus
}

const changeAvanse = (avanse) => {
  order.avanse = avanse
}

const changeDeposit = (deposit) => {
  order.ad_deposit = deposit
}

const clearFishki = () => {
  changeBonus(0)
  changeDeposit(0)
  changeAvanse(0)
}

const sendNewOrder = async (channe = 'ultimatum-shop') => {
  try {
    const items = []
    for (const one of basket) {
      const as = one.activityspecifications
      const ms = {
        productid: one.productid,
        specification: as ? one.specification : null,
        guid: !as ? one.dataproduct.guid : `${one.dataproduct.guid}#${one.dataproduct.specifications.find(x => x._id == one.specification).guid}`,
        name: !as ? one.name : `${one.name} ${one.dataproduct.specifications.find(x => x._id == one.specification).name}`,
        realprice: one.realprice,
        price: one.price,
        discounts: {
          discount_activity: one.discount_activity,
          discount_type: null,
          discount_promocode: null,
          discount_procent: one.discount_procent,
          discount_sum: one.discount_sum
        },
        cashback: {
          remove_bonus: one.remove_bonus,
          remove_bonus_status: one.remove_bonus_status,
          add_bonus: 0,
          add_bonus_status: false
        },
        quantity: one.quantity,
        marking: one.marking,
        actual_delivery_cost: 0,
        customer_delivery_cost: 0,
        payment_status: 'not-paid',
        status:'successfully'
      }

      if (one.marking) {
        ms.marking_code = one.marking_code
        ms.marking_code_decode = one.marking_code_decode
        ms.marking_uuid = one.marking_uuid
      }

      items.push(ms)
    }
    const data = {
      contragent: client.value._id,
      channel: channe,
      items: items,
      bonus: order.bonus,
      manager: user.value._id,
      promocodename:order.promocodename,
      promocode:order.promocode,
    }
    if(order.refferal !== null){
      if(order.refferal !== client.value._id){
          data['refferal'] = order.refferal
      }
      
  }
    const orderNew = await axios.post('https://api.ultimatum.store/order/addnew', data)
    return {
      orderID: orderNew._id,
      orderNumber: orderNew.ordernumber
    }
  } catch (e) { console.log(e) }
}

const createPayment = async (orderID, orderNumber, sd = 0,type = false,payment='bank-card') => {
  try {

    var enTi = 'podgornov'
    var typeSh = 'shop'
    if(order.markingorder){
      var enTi = 'damanina'
    }


    if(type){
      enTi = 'damanina'
      typeSh = 'online'
      var status = 'not-paid'
      if(payment == 'cash'){status = 'paid';enTi = 'damanina'}
      const data = {
        orderNumber: orderID,
        type: 'online',
        data: {
          service: payment,
          price: order.paymentorder.payment,
          status: status,
          type: 'in',
          client: client.value._id,
          entity: enTi
        }
      }
      const paymentId = await axios.post('https://api.ultimatum.store/payment/createpayment', data)
      await axios.post('https://api.ultimatum.store/order/updateOrderInfo', ['payment', orderID, paymentId._id])
    }
    else{
        const vyboroplaty = order.paymentorder.type.filter(x => x.activity == true)
        if (vyboroplaty.length > 0) {
          for (const one of vyboroplaty) {
            const data = {
              orderNumber: orderNumber,
              type: 'shop',
              data: {
                service: one.type,
                price: one.price - sd,
                status: 'paid',
                type: 'in',
                client: client.value._id,
                entity: one.entity
              }
            }
            const paymentId = await axios.post('https://api.ultimatum.store/payment/createpayment', data)
            await axios.post('https://api.ultimatum.store/order/updateOrderInfo', ['payment', orderID, paymentId._id])
          }
        }
    }
    if (order.avanse !== 0) {

      const datas = {
        orderNumber: orderNumber,
        type: typeSh,
        data: {
          service: 'return-advance',
          price: order.avanse,
          status: 'paid',
          type: 'in',
          client: client.value._id,
          entity:enTi
        }
      }

      const paymentIdAvanse = await axios.post('https://api.ultimatum.store/payment/createpayment', datas)
      await axios.post('https://api.ultimatum.store/order/updateOrderInfo', ['payment', orderID, paymentIdAvanse._id])


    }

    if (order.ad_deposit !== 0) {

      const datas = {
        orderNumber: orderNumber,
        type: typeSh,
        data: {
          service: 'endorsement-deposit',
          price: order.ad_deposit,
          status: 'paid',
          type: 'in',
          client: client.value._id,
          entity: enTi
        }
      }
      const paymentIdAdDeposit = await axios.post('https://api.ultimatum.store/payment/createpayment', datas)
      await axios.post('https://api.ultimatum.store/order/updateOrderInfo', ['payment', orderID, paymentIdAdDeposit._id])

    }
  } catch (e) { console.log(e) }
}
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
const promocodeUse = async (data) => {

  for(let one of basket) {
     
         one.changePromocode(data)
  }
  order.promocode = data._id
  order.promocodename = data.name
 
}


const createDelivery = async(orderID) =>{
  try{
    var dos = {
      "delivery":{
      "adress": "198330, Ананьевский пер., 5, стр. 9, Москва",
      "time": "пн-вс: 10.00-20.00",
      "type": "pickup-point",
      "coords": [
          "55.77146577304853",
          "37.63454518651547"
      ],
      "code": "107045",
      "activity": false,
      "price": 0,
      "costprice":0,
      "day": 1,
      "payment": null,
      "service":"shop",
      "track":"0"
      },
      "client":{
        "name":`${client.value.firstname} ${client.value.lastname}`,
        "phone":client.value.phone
      }
     }

     var crea = await axios.post(`https://api.ultimatum.store/delivery/createdocument`,dos)
     await axios.post('https://api.ultimatum.store/order/updateOrderInfo',['delivery',orderID,crea.id])
     return true
  }
  catch(e){console.log(e)}
 }

const fishkiUser = () => {
  const bonus = computed(() => {
    if (!activity.value) { return 0 }
    const b = client.value.bonus ? client.value.bonus : 0
    if (b !== 0) {
      if (b > order.producttotal) {
        var ns = order.producttotal - order.avanse
        if (ns < 0) { return 0 }
        return order.producttotal - order.avanse
      }
    }
    var ns = b - order.avanse
    if (ns < 0) { return 0 }
    return ns
  })

  const avans = computed(() => {
    if (!activity.value) { return 0 }
    const b = client.value.avans ? client.value.avans : 0
    if (b !== 0) {
      if (b > order.producttotal) { return order.producttotal }
    }
    return b
  })

  const ad_deposit = computed(() => {
    if (!activity.value) { return 0 }
    const b = client.value.ad_deposit ? client.value.ad_deposit : 0
    if (b !== 0) {
      if (b > order.producttotal) { return order.producttotal }
    }
    return b
  })

  watch(viewbasket,() => {
    if(viewbasket.value){
      var i = 0
      for(let one of basket){
        var x = new prototypeItem(one.productid,one.activityspecifications,one.dataproduct,one.specification,one.quantity)
        deleteProduct(i)
        x.addToCard()
        i++
      }
    }
  })

  return {
    bonus,
    avans,
    ad_deposit
  }
}


export {
  basket,
  order,
  changeBonus,
  addBasket,
  deleteProduct,
  sendNewOrder,
  createPayment,
  deleteBasket,
  changeAvanse,
  changeDeposit,
  fishkiUser,
  viewbasket,
  removePayment,
  clearFishki,
  createDelivery,
  promocodeUse
}
