import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
// import './registerServiceWorker'
import AppLayout from './layouts/AppLayout'
import slotokno from './components/general/slotokno'

createApp(App)
  .use(router)
  .component('AppLayout', AppLayout)
  .component('slotokno', slotokno)
  .mount('#app')
