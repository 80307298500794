import { computed, ref, watch } from 'vue'
import { notSet } from '@/controlles/general'
import { addBasket, basket, deleteProduct } from '@/controlles/basket/basket'
import { newclient, isBirthday } from '@/controlles/client'

class prototypeItem {
  constructor (p, a, d, ss = 0,qua = 1) {
    this.timeadd = new Date().getTime()
    this.productid = ref(p)
    this.quantity = ref(qua)
    this.specification = ref(ss)
     this.newprice = ref(0)
     this.promocodename = ref(null)
        this.promocodesale = ref(0)
    this.w = watch(this.specification, () => {
      const ins = basket.findIndex(x => x.productid == this.productid.value && x.specification == this.specification.value && x.timeadd !== this.timeadd)
      if (ins !== -1) {
        const my = basket.findIndex(x => x.productid == this.productid.value && x.specification == this.specification.value && x.timeadd == this.timeadd)
        notSet('Данный товар уже добавлен')
        deleteProduct(my)
      }
    })
    this.activityspecifications = ref(a)
    this.dataproduct = d
    this.name = this.dataproduct.name,
    this.artikul = this.dataproduct.artikul,
    this.podzakaz = this.dataproduct.model.podzakaz || false
    this.discount_procent = computed(() => {
      let sale = 0
      if (!this.activityspecifications.value) {
        sale = this.dataproduct.sale
      } else {
        if (this.specification.value == 0) {
          sale = 0
        } else {
          sale = this.dataproduct.specifications.find(x => x._id == this.specification.value).sale
        }
      }

      if (newclient.value || isBirthday.value) {
        if (sale < 5) {
          sale = 5
        }
      }
      if(this.promocodesale.value > sale){
        return this.promocodesale.value
      }
      return sale
    })
    this.discount_sum = computed(() => {
      if (this.discount_procent.value == 0) {
        return 0
      } else {
        return Math.round((this.realprice.value / 100) * this.discount_procent.value)
      }
    })
    this.discount_activity = computed(() => { if (this.discount_procent.value !== 0) { return true } return false })
    this.remove_bonus = ref(0)
    this.remove_bonus_status = computed(() => { if (this.remove_bonus.value !== 0) { return true } return false })
    this.realprice = computed(() => {
      if (!this.activityspecifications.value) {
        return this.dataproduct.price.count
      }
      if (this.specification.value == 0) {
        return this.dataproduct.specifications[0].price.count
      } else {
        return this.dataproduct.specifications.find(x => x._id == this.specification.value).price.count
      }
    })

    this.price = computed(() => {
      if(this.newprice.value !== 0) {
        return this.newprice.value
      }
      return this.realprice.value - this.remove_bonus.value - this.discount_sum.value
    })
    this.marking = ref(this.dataproduct.model.marking)
    this.marking_code = ref(null)
    this.marking_code_decode = ref(null)
    this.marking_uuid = ref(null)
    this.nextorder = computed(() => {
      if (this.marking.value) {
        if (this.marking_code.value == null) { return false }
        return true
      }
      return true
    })
    this.balance = computed(() => {
      const z = 'Магазин'
      if (!this.activityspecifications.value) {
        if (this.dataproduct.balance.find(x => x.name == z)) {
          return this.dataproduct.balance.find(x => x.name == z).count || 0
        }
        return 0
      }
      if (this.specification.value == 0) {
        return this.dataproduct.specifications[0].balance.find(x => x.name == z).count || 0
      } else {
        return this.dataproduct.specifications.find(x => x._id == this.specification.value).balance.find(x => x.name == z).count || 0
      }
    })
    this.blockadditem = computed(() => {
      if (this.podzakaz) {
        return false
      }
      return this.quantity.value >= this.balance.value
    })
    this.quantityChange = (type) => {
      if (!this.activityspecifications.value) {
        if (type == 'add') {
          if (!this.blockadditem.value) {
            this.quantity.value++
          }
        }
        if (type == 'minus') {
          if (this.quantity.value > 1) {
            this.quantity.value--
          }
        }
      } else {
        if (this.specification.value == 0) {
          notSet('Выберите размер товара')
          return false
        } else {
          if (type == 'add') {
            if (!this.blockadditem.value) {
              this.quantity.value++
            }
          }
          if (type == 'minus') {
            if (this.quantity.value > 1) {
              this.quantity.value--
            }
          }
        }
      }
    }
    this.changePromocode = (d) => {
      this.promocodename.value = d.name
      this.promocodesale.value = d.sale
    }
    this.proverkaProdazhi = (id) => {
      if (!this.activityspecifications.value) { return true }
      const number = this.dataproduct.specifications.find(x => x._id == id).balance.find(x => x.name == 'Магазин').count
      if (number == 0) { return false }
      return true
    }
  }

  addToCard () {
    if (this.activityspecifications.value && this.specification.value == 0) {
      notSet('Выберите размер товара')
      return false
    } else {
      addBasket(this)
      return true
    }
  }

  changeNanQuantity () {
    if (isNaN(parseInt(this.quantity.value)) || parseInt(this.quantity.value) < 1) {
      this.quantity.value = 1
    }
  }

}

export {
  prototypeItem
}
