<template>
    <div class="scanners content">
      <div class="blockvideo">
               <div class="linersScanner" v-if="activityScanner"></div>
               <video class="camera" width="100%" height="200" ref="camera" muted autoplay="autoplay" playsinline="playsinline"></video>
      </div>
      <div class="twobuttons">
          <button class="big_button nocopy" :class="{viewScan:activityScanner}" @touchstart="gotoScannerActivity(true)" @touchend="gotoScannerActivity(false)">СКАНИРОВАТЬ</button>
          <button  class="goToScan flash nocopy" :class="{activityScannerButtonFanarik:finarik}" @click="finarik = !finarik">
            <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M17.5 2C17.5 1.58579 17.1642 1.25 16.75 1.25C16.3358 1.25 16 1.58579 16 2V3C16 3.41421 16.3358 3.75 16.75 3.75C17.1642 3.75 17.5 3.41421 17.5 3V2ZM21.5303 2.46967C21.2375 2.17678 20.7626 2.17678 20.4697 2.46967L18.4697 4.46967C18.1768 4.76256 18.1768 5.23744 18.4697 5.53033C18.7626 5.82322 19.2375 5.82322 19.5303 5.53033L21.5303 3.53033C21.8232 3.23744 21.8232 2.76256 21.5303 2.46967ZM8.03035 17.0303L9.53035 15.5303C9.82324 15.2374 9.82324 14.7626 9.53035 14.4697C9.23745 14.1768 8.76258 14.1768 8.46969 14.4697L6.96969 15.9697C6.67679 16.2626 6.67679 16.7374 6.96969 17.0303C7.26258 17.3232 7.73745 17.3232 8.03035 17.0303ZM8.25002 6V10.6893L3.03035 15.909C2.15167 16.7877 2.15167 18.2123 3.03035 19.091L4.90903 20.9697C5.78771 21.8484 7.21233 21.8483 8.09101 20.9697L13.3107 15.75H18C18.1989 15.75 18.3897 15.671 18.5303 15.5303L20.4697 13.591C21.3484 12.7123 21.3484 11.2877 20.4697 10.409L13.591 3.53033C12.7123 2.65165 11.2877 2.65165 10.409 3.53033L8.46969 5.46967C8.32903 5.61032 8.25002 5.80109 8.25002 6ZM11.4697 4.59099C11.7626 4.2981 12.2375 4.2981 12.5303 4.59099L19.409 11.4697C19.7019 11.7626 19.7019 12.2374 19.409 12.5303L18 13.9393L10.0607 6L11.4697 4.59099ZM9.75002 7.81066L16.1894 14.25H13.3107L9.75002 10.6893V7.81066ZM9.00002 12.0607L11.9394 15L7.03035 19.909C6.73745 20.2019 6.26258 20.2019 5.96969 19.909L4.09101 18.0303C3.79811 17.7374 3.79811 17.2626 4.09101 16.9697L9.00002 12.0607ZM21 8H22C22.4142 8 22.75 7.66421 22.75 7.25C22.75 6.83579 22.4142 6.5 22 6.5H21C20.5858 6.5 20.25 6.83579 20.25 7.25C20.25 7.66421 20.5858 8 21 8Z" fill="#212121"/></svg>
          </button>
          <button class="reload" @click="reloadCamera()">
            <svg height="32px" id="Layer_1" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M28,16c-1.219,0-1.797,0.859-2,1.766C25.269,21.03,22.167,26,16,26c-5.523,0-10-4.478-10-10S10.477,6,16,6  c2.24,0,4.295,0.753,5.96,2H20c-1.104,0-2,0.896-2,2s0.896,2,2,2h6c1.104,0,2-0.896,2-2V4c0-1.104-0.896-2-2-2s-2,0.896-2,2v0.518  C21.733,2.932,18.977,2,16,2C8.268,2,2,8.268,2,16s6.268,14,14,14c9.979,0,14-9.5,14-11.875C30,16.672,28.938,16,28,16z"/></svg>
          </button>
      </div>
    </div>
  </template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import { notSet } from '@/controlles/general'

const props = defineProps(['format'])
const emit = defineEmits(['barcode'])
const barcodeDetector = ref(null)
const localStream = ref(null)
const device = ref([])
const error = ref('')
const interval = ref(null)
const opendevice = ref(null)
const activityScanner = ref(false)
const finarik = ref(false)
const streamId = ref(null)
const pickaudio = typeof Audio !== 'undefined' && new Audio('/audio/scan.mp3')
const erroraudio = typeof Audio !== 'undefined' && new Audio('/audio/error.mp3')
const camera = ref(null)

const gotoScannerActivity = (data) => { activityScanner.value = data }

const openCamera = async () => {
  try {
    const getDevice = await navigator.mediaDevices.enumerateDevices()
    if (getDevice.length > 0) {
      for (const one of getDevice) {
        if (one.kind == 'videoinput') { device.value.push(one) }
      }
    }
    opendevice.value = device.value[device.value.length - 1].deviceId

    const constraints = { video: { deviceId: opendevice.value }, audio: false }
    window.localStream = await navigator.mediaDevices.getUserMedia(constraints)

    streamId.value = window.localStream.id
    camera.value.srcObject = window.localStream
  } catch (e) { console.log(e) }
}

const stop = () => { if (window.localStream) { window.localStream.getTracks().forEach(track => track.stop()) } }

const reloadCamera = async () => {
  stop()
  await openCamera()
}

const startDecoding = (a) => {
  if (a) {
    if (window.localStream.id !== streamId.value) {
      stop()
      openCamera()
    }
    interval.value = setInterval(decode, 500)
  } else {
    clearInterval(interval.value)
  }
}

const decode = async () => {
  try {
    const barcodes = await barcodeDetector.value.detect(camera.value)
    pickaudio.play()
    startDecoding(false)
    emit('barcode', barcodes[0].rawValue)
  } catch (e) {}
}

watch(activityScanner, (val) => {
  if (val) {
    startDecoding(true)
  } else { startDecoding(false) }
})

watch(finarik, (val) => {
  const track = localStream.value.getVideoTracks()[0]
  track.applyConstraints({
    advanced: [{ torch: finarik.value }]
  })
})

onMounted(async () => {
  if (!('BarcodeDetector' in window)) {
    notSet('Ваш телефон не подерживает сканирование')
    openCamera()
  } else {
    stop()
    barcodeDetector.value = new BarcodeDetector()
    openCamera()
  }
})

</script>

  <style scoped>

  .scanners{
    width: calc(100% + 10px);
    margin-left: -5px;
    background: var(--black);
    padding-top: 20px;
  }

  select{
    margin: 10px 0px;
    padding: 5px;
    width: calc(100% - 10px);
    text-align: center;
    border:1px solid #cecece;
    border-radius: 7px;
    font-size: 18px;
  }
  video{
    width: 100%;
  }
  .linersScanner{
      width: 100%;
      height: 1px;
      background: red;
      position: absolute;
      left: 0;
      animation:liner 500ms infinite alternate-reverse ;
      opacity:0.5;
  }

  @keyframes liner {
      0%{
          margin-top:0px;
      }
      100%{
          margin-top:200px;
      }
  }

  .big_button {
    border:none;
    width: 100%;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: var(--black);
    color: #fff;
    margin: 0;
    transition: 200ms;
  }

  .viewScan{
    background: #fff;
    color: #000;

  }

  .twobuttons{
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    justify-items: center;
    gap: 10px;
    width: 70%;
    margin: 0 auto;
    margin-top: 10px;
    padding-bottom: 20px;

  }

  .goToScan{
      width: 50px;
      background: var(--new);
      height: 50px;
      display: grid;
      align-items: center;
      justify-items: center;
      border-radius: 100%;
      transition: 500ms;
      border: 3px solid var(--new);
      box-shadow: 0px 0px 0px 3px var(--new);
    }

  .goToScan svg{
      width: 30px;
      height: 30px;
  }

  .goToScan svg path{
      fill:#fff;
  }

  .reload{
    background: var(--black);
    border: none;
    border-radius: 50%;
  }

  .reload svg{

  }

  .reload svg path{
    fill:#fff;

  }
  </style>
