<template>
        <div class="header">

            <div class="back" @click="rt.go(-1)" :class="{viewback:r.path !== '/'}">
                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.60107 0.39935C7.93301 0.731296 7.93301 1.26949 7.60107 1.60143L3.05211 6.15039H15C15.4695 6.15039 15.85 6.53095 15.85 7.00039C15.85 7.46983 15.4695 7.85039 15 7.85039H3.05211L7.60107 12.3994C7.93301 12.7313 7.93301 13.2695 7.60107 13.6014C7.26912 13.9334 6.73093 13.9334 6.39898 13.6014L0.398984 7.60143C0.067038 7.26949 0.067038 6.7313 0.398984 6.39935L6.39898 0.39935C6.73093 0.0674042 7.26912 0.0674042 7.60107 0.39935Z" fill="white"/>
                </svg>
            </div>

            <div class="logo" @click="rt.push({name:'home'})">
                <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8695 15.9484L12.8726 19H4.12735L1.13049 15.9699V4.27015H0V0H7.41217V4.27015H6.29235V14.126L6.90025 14.7191H10.1211L10.7077 14.126V4.27015H9.58783V0H17V4.27015H15.8695V15.9484Z" fill="#2A4751"></path></svg>
            </div>

            <div class="logo menuTop" @click="headMenu = true">
                <svg height="32px" id="icon" style="enable-background:new 0 0 32 32;" version="1.1" viewBox="0 0 32 32" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><circle cx="8" cy="16" r="2"/><circle cx="16" cy="16" r="2"/><circle cx="24" cy="16" r="2"/><rect class="st0" height="32" id="_Transparent_Rectangle_" width="32"/></svg>
            </div>

            <div class="clientMenu" v-if="activity">
                <img :src="client.avatar !== null ? 'https://api.ultimatum.store/images/' + client.avatar : '/img/nophoto.jpg'" @click="oknoUser(true)">
                <transition name="slimeright">
                   <basketviewokno v-if="viewbasket"/>
                </transition>
            </div>

            <div class="basket" v-if="activity" @click="viewbasket = true">
                <svg enable-background="new 0 0 50 50" height="50px" id="Layer_1" version="1.1" viewBox="0 0 50 50" width="50px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M8,14L4,49h42l-4-35H8z" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><rect fill="none" height="50" width="50"/><path d="M34,19c0-1.241,0-6.759,0-8  c0-4.971-4.029-9-9-9s-9,4.029-9,9c0,1.241,0,6.759,0,8" fill="none" stroke="#000000" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/><circle cx="34" cy="19" r="2"/><circle cx="16" cy="19" r="2"/></svg>
                <span>{{ basket.length }}</span>
            </div>
            <div v-else></div>

        </div>
        <transition name="slimeright">
               <slotokno :close="() => headMenu = false" :text="'Закрыть меню'" v-if="headMenu"><headermenu /></slotokno>
        </transition>
</template>

<script setup>
import headermenu from './headermenu.vue'
import { ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { client, activity, viewUser, oknoUser } from '@/controlles/client'
import basketviewokno from '../client/basket.vue'
import { basket, viewbasket } from '@/controlles/basket/basket'
import { headMenu } from '@/controlles/general'

const r = useRoute()
const rt = useRouter()

watch([r], () => {
  if (headMenu.value == true) { headMenu.value = false }
})

</script>

<style scoped>
.header{
    height: 60px;
    width: calc(100% - 20px);
    left: 0;
    top: 0;
    position: fixed;
    display: grid;
    background: var(--black);
    grid-template-columns: auto auto 1fr auto auto;
    align-items: center;
    padding: 0px 10px;
    z-index: 1;

}

.logo{
    background: var(--black);
}
.logo svg {
    width: 30px;
    height: auto;
}
.logo svg path{fill:var(--white)}

.back{width: 0px;transition: 200ms;}
.viewback{width: 35px;}

.back svg{
    height: 20px;
    margin-right: 10px;
    width: auto;

}

.menuTop{
    margin-left: 10px;
}
.menuTop svg{
    width: 38px;
    fill:none;
}

.menuTop svg circle,.menuTop svg line{
    stroke: var(--white);
    fill: var(--white);
}

.clientMenu{
    justify-self: right;
}
.clientMenu img{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    margin-right: 10px;
}

.basket{
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    color: #fff;
    width: max-content;
    gap: 5px;
}

.basket span{

    font-size: 24px;

}

.basket svg{
    width: 27px;
    height: 40px;
    display: block;
    object-fit: contain;
    transform: translateY(-4px);
}
.basket svg path{
    stroke:var(--white)
}

.basket svg rect{

}

</style>
