<template>
    <div class="upd">
        <p>в АРМ есть изменение, обновите по кнопке ниже.</p>
        <div class="big_button clickbutton button_light" @click="updatePwa()">ОБНОВИТЬ</div>
    </div>
</template>

<script setup>
const updatePwa = () => { window.location.reload() }
</script>

<style scoped>

.upd{
    position: fixed;
    bottom: 0;
    height: 200px;
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
    left: 0;
    background: var(--black);
}

.upd p {
    color:var(--white)
}

.button_light{
    width: calc(100% - 40px);
}

</style>
