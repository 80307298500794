<template>
  <component :is="layout" :class="{ blur: loading }" class="animation">
    <slot />
  </component>
  <Loadings />

</template>

<script setup>
import AppLayoutDefault from '@/layouts/AppLayoutDefault'
import AppLayoutAuth from '@/layouts/AppLayoutAuth'
import Loadings from '@/components/general/loading'
import { loading } from '@/controlles/general'
import { activity } from '@/controlles/auth'
import { computed } from 'vue'

const layout = computed(() => {
  if (activity.value) {
    return AppLayoutDefault
  } else {
    return AppLayoutAuth
  }
})

</script>

<style scoped>
.animation {
  transition: 500ms;

}
.blur {
  /*transform: scale(0.5); filter:blur(1px)*/

}

</style>
