<template>
     <div class="button nocopy record" :class="{voiceActivity:voiceActivity}" @click="!voiceActivity ? voice() : stopvoice()">{{!voiceActivity ? 'Записать' : 'Остановить'}} <div class="circle"></div></div>
</template>

<script setup>
import { ref } from 'vue'

const gettext = defineModel('gettext')
const voiceActivity = ref(false)
const recognition = ref(null)
const voice = async () => {
  try {
    recognition.value = new window.webkitSpeechRecognition()
    recognition.value.lang = 'ru-RU'
    recognition.value.continuous = false
    recognition.value.interimResults = false
    voiceActivity.value = true
    recognition.value.addEventListener('result', event => {
      let result = ''
      for (let i = 0; i <= event.resultIndex; i++) {
        if (event.results[i].isFinal) {
          result += event.results[i][0].transcript
        } else {

        }
      }
      gettext.value += result + ' '
    })
    recognition.value.addEventListener('end', stopvoice)
    recognition.value.start()
  } catch (e) { console.log(e) }
}

const stopvoice = () => {
  recognition.value.stop()
  voiceActivity.value = false
}

</script>

<style scoped>

.record{
display: grid;
grid-template-columns: auto auto;
justify-content: center;
gap: 10px;
margin-bottom: 20px;
}

.record .circle {
width: 30px;
height: 30px;
border-radius: 100%;
background: red;
transition: 500ms;

}

.voiceActivity .circle{
  animation: heartbeat 1s ease-in-out infinite both;

}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.81);
            transform: scale(0.81);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.88);
            transform: scale(0.88);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.77);
            transform: scale(0.77);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
</style>
