import { reactive, computed, ref } from 'vue'
import { viewRole } from '@/role/rolechannel'
const user = reactive({})
const role = ref(null)
const activity = ref(false)
const channelRole = ref(null)

const userState = () => {
  const setUser = (data, roleID = []) => {
    if (Object.keys(data).length !== 0) {
      user.value = data
      role.value = data.role.slug
      channelRole.value = viewRole(data.role.slug)
      if (Object.keys(data).length == 0) {
        activity.value = false
      } else { activity.value = true }
    }
  }
  return {
    user: computed(() => user.value),
    role: computed(() => role.value),
    activity: computed(() => activity.value),
    channelRole: computed(() => channelRole.value),
    setUser
  }
}

export default userState
