<template>

    <div class="okno_full">
                <div class="headnot" @click="uioknoClose()"><h5>{{ props.text }}</h5></div>
                    <div class="conents_okno">
                        <slot/>
                    </div>
                </div>

</template>

<script setup>
const props = defineProps(['close', 'text'])
const uioknoClose = () => { props.close() }
</script>

<style scoped>

</style>
