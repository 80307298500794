import { ref, reactive, shallowRef } from 'vue'
import axios from '@/utils/axios'

const loading = ref(false)
const noloading = ref(false)
const not = ref({
  view: false,
  text: ''
})

const updateBd = ref({
  upd:false,
  type:null
})

const updateFunction = (type) => {
  updateBd.value.type = type
  updateBd.value.upd = true
  setTimeout(() => {
     updateBd.value.upd = false
     updateBd.value.type = null
   },1000)
}

const uiokno = ref({
  view: false,
  text: 'Закрыть'
})

const uioknoClose = () => {
  uiokno.value = {
    view: false,
    text: 'Закрыть'
  }
}

const suc = ref({
  view: false,
  text: '',
  type: 'good'
})

const headMenu = ref(false)
const viewPwa = ref(false)

const validPhones = (phone) => {
  let userPhone = String(phone).replace(/[^0-9]/g, '')
  if (userPhone[0] == 8) {
    const telefon = [...userPhone]
    telefon[0] = 7
    userPhone = telefon.join('')
  }
  return userPhone
}

const trObj = (data) => {
  if (Object.keys(data).length == 0) return false
  return true
}

const notSet = (text) => {
  not.value = {
    view: true,
    text: text
  }

  setTimeout(() => not.value = {
    view: false,
    text: ''
  }, 1000)
}

const setSuc = (text, type) => {
  suc.value = {
    view: true,
    text: text,
    type: type || 'good'
  }

  setTimeout(() => suc.value = {
    view: false,
    text: '',
    type: 'good'
  }, 10000)
}

const orderInfoStatus = {
  new: 'Новый',
  'canceled-by-user': 'Отменен клиентом',
  canceled: 'Отменен менеджером',
  delete: 'Удалён клиентом',
  paid: 'Оплачен',
  activated: 'Активирован',
  'send-to-assembling': 'Передан в комплектацию',
  completed: 'Скомплектован',
  'send-to-delivery': 'Передан в доставку',
  delivering: 'В пути',
  successfully: 'Завершен',
  'ultimatum-store': 'сайт',
  'ultimatum-shop': 'магазин',
  'complicated-case': 'Ручная обработка',
  'cancel-other': 'Отменен',
  'completed-successfully-crm': 'Завершен',
  return: 'Возврашен'
}

const channelInfo = {
  'ultimatum-shop': 'Магазин',
  'ultimatum-store': 'Сайт RU',
  'ultimatum-crm': 'CRM',
  'ultimatum-com': 'Сайт COM',
  'ultimatum-sportmaster': 'Спортмастер'
}

const goodData = (data) => {
  if (data) {
    let x = data.split('T')[0]
    x = x.split('-')
    return `${x[2]}-${x[1]}-${x[0]}`
  }
  return ' '
}

const viewOknoComponent = shallowRef({
  component: null,
  view: false
})

const openOknoComponent = (component) => {
  viewOknoComponent.value = { component: component, view: true }
}
const closeOknoComponent = () => {
  viewOknoComponent.value = { component: null, view: false }
}

const newObject = (d) => JSON.parse(JSON.stringify(d))

const addImage = async (data) => {
  try {
    const folders = data.folders || 'general'
    const a = []
    for (const one of data.file) {
      const formData = new FormData()
      formData.append('file', one)
      const uploads = await axios.post(`https://api.ultimatum.store/uploadfile/image/${folders}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      a.push(uploads)
    }
    return a
  } catch (e) {
    console.log(e)
  }
}

export { loading, validPhones, trObj, notSet, not, suc, setSuc, uiokno, uioknoClose, viewPwa, headMenu, orderInfoStatus, channelInfo, goodData, viewOknoComponent, openOknoComponent, closeOknoComponent, newObject, addImage, noloading,updateFunction,updateBd }
