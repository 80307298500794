<template>

      <div class="blockorders" v-if="!!allorder">
          <div class="findZakaz">
            <input type="text" placeholder="номер заказа" v-model="findOrder">
          </div>
          <item v-for="one of MyAllOrder" :o="one" :options="props.options"/>

      </div>
      <div class="noOrders" v-else>
        <p>Заказов нет</p>
      </div>

</template>

<script setup>
import { client } from '@/controlles/client'
import { ref, computed } from 'vue'
import axios from '@/utils/axios'
import item from '@/components/order/item.vue'
const url = `${process.env.VUE_APP_API_BD}`
const props = defineProps({ options: { default: 'default' } })
const loading = ref(true)
const findOrder = ref('')
const allorder = ref([])
const getAllOrders = async () => {
  try {
    const data = await axios.post(`${url}/arm/client/vieworders`, { client: client.value._id })
    allorder.value = data
    loading.value = false
  } catch (e) {
    loading.value = false
  }
}
await getAllOrders()

const MyAllOrder = computed(() => {
  if (findOrder.value.length > 2) {
    return allorder.value.filter(x => x.ordernumber == findOrder.value || x.crmid == findOrder.value || x.crmnumber == findOrder.value)
  }

  return allorder.value
})

</script>

<style scoped>

.blockorders{
  margin-top:20px;
  display: grid;
  gap:10px;
}
.noOrders{
    display: grid;
    align-items: center;
    justify-items: center;
    height: 90vh;

}

</style>
