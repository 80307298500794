import axios from 'axios'
import { loading, noloading } from '@/controlles/general'
import { axioserror } from '@/utils/error'

axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.get['Accept-Language'] = 'ru'

axios.interceptors.request.use(
  (config) => {
    if (!noloading.value) { loading.value = true }
    return config
  },
  (error) => {
    loading.value = false
    return Promise.reject(axioserror(error))
  }
)

axios.interceptors.response.use(
  (res) => {
    loading.value = false
    return res.data
  },
  (error) => {
    loading.value = false
    return Promise.reject(axioserror(error))
  }
)

export default axios
