<template>
  <div>
    <AppLayout>
      <router-view />
    </AppLayout>
  </div>
</template>

<style>
@font-face {
font-family: "conke";
src: url("/public/fonts/c2.woff2") format("woff2");
font-style: normal;
font-weight: 100;
font-display: swap;
}

@font-face {
font-family: "conke";
src: url("/public/fonts/c2.woff2") format("woff2");
font-style: normal;
font-weight: 200;
font-display: swap;
}

@font-face {
font-family: "conke";
src: url("/public/fonts/c3.woff2") format("woff2");
font-style: normal;
font-weight: 300;
font-display: swap;
}

@font-face {
font-family: "conke";
src: url("/public/fonts/c4.woff2") format("woff2");
font-style: normal;
font-weight: 400;
font-display: swap;
}

@font-face {
font-family: "conke";
src: url("/public/fonts/c5.woff2") format("woff2");
font-style: normal;
font-weight: bold;
font-display: swap;
}

html{
  background: var(--lightgrey);
  transition: 500ms;
  overflow: hidden scroll;
}

h1,h2,h3,h4,h5{
  font-family: "conke";
  font-weight: 200;
  color:var(--black-color)
}

p,span,a{
  font-family: "conke";
  font-size: 16px;
  font-weight: 200;
  color:var(--black-color)
}

body {
  font-family: "conke";
  margin: 0;
  touch-action: pan-x pan-y;
  width: calc(100% - 20px);
  padding: 10px;
  overflow: hidden scroll;
}

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #000;
}

:root {
  --green: green;
  --white: #fff;
  --black: #222222;
  --grey: #f6f6f6;
  --blackgrey: #cfcfcf;
  --blue: #222;
  --red: #222;
  --boxshadowhover: 0px 0px 20px 0px rgb(0 0 0 / 23%);
  --boxshadowlight: 0 20px 27px 0 rgb(0 0 0 / 5%);
  --boxclick:0px 2px 10px 2px #12323e;
  --boxerror:0px 0px 5px 1px #fe6b58;
  --boxgoord:0px 0px 5px 1px green;
  --light: #fbf8f4;
  --lightgrey: #f8f8f9;
  --lightblue: #88989e;
  --input: #efefef;
}

:root.dark-theme {
  --green: green;
  --white: #222225;
  --input: #222225;
  --black: #a9a9b2;
  --grey: #f6f6f6;
  --blackgrey: #cfcfcf;
  --blue: #a2a5b9;
  --red: #fe6b58;
  --boxshadowhover: 0px 0px 20px 0px rgb(0 0 0 / 23%);
  --boxerror:0px 0px 5px 1px #fe6b58;
  --boxgoord:0px 0px 5px 1px green;
  --light: #262626;
  --lightgrey: #3c3a40;
  --lightblue: #88989e;
}

input,
select {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid var(--blackgrey);
    transition: all 0.3s;
    background: var(--white);
    color: var(--black);
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 100;
    font-family: 'conke';
}
input:focus,
select:focus {
  box-shadow: var(--boxshadowhover);
}

.inputerror{
  box-shadow: var(--boxerror);
}

.inputgood{
  box-shadow: var(--boxgoord);
}

input:focus-visible,
select:focus-visible {
  outline: none;
  background: var(--white);
}

.inputtitle{}
.inputtitle p{
  margin-bottom: 5px;
}

button{
  font-family: 'conke';
}

.button{
    background: var(--blue);
    color: var(--white);
    text-align: center;
    font-size: 18px;
    padding: 5px;
    font-weight: 100;
    transition: 200ms;
    min-height: calc(60px - 20px);
    display: grid;
    align-items: center;
    font-family: 'conke';
}

.big_button{
    text-align: center;
    font-size: 18px;
    padding: 5px;
    font-weight: 200;
    margin-bottom: 20px;
    text-transform: uppercase;
    transition: 200ms;
    min-height: calc(60px - 20px);
    display: grid;
    align-items: center;
    font-family: 'conke';
}
.clickbutton{
  transition: 200ms;
}
.clickbutton:active{
  box-shadow: var(--boxclick);
  opacity: 0.5;
}

.big_button .number{
  color: var(--red);
}

.button_red{
  background: var(--red);
  color: var(--white)
}

.button_blue{
  background: var(--black);
  color: var(--white)
}

.button_light{
  border: 1px solid var(--blackgrey);
  background: var(--white);
}

.okno_full{
    position: fixed;
    top: 0;
    background: var(--lightgrey);
    z-index: 99999;
    height: 100vh;
    left: 0;
    overflow: scroll;
    width: 100%;
}
.conents_okno{
    padding: 60px 5px 5px 5px;
    width: calc(100% - 10px);
    overflow-x: hidden;
}
.headnot{

    background: var(--black);
    height: 60px;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 20px;
    align-items: center;
    width: calc(100% - 10%);
    padding: 0px 5%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;

}

.headnot h5{
    color: var(--white);
    font-weight: 300;
    font-size: 16px;
    text-transform: uppercase;
}

.headnot svg{
    width: 20px !important;
    height: auto !important;
}
.headnot svg path{
  fill:var(--white) !important;
}

.buttons{
    text-align: center;
    padding: 5px;
    width: calc(100% - 10px);
    transition: 500ms;
    text-transform: uppercase;
    font-family: 'conke';
}
.buttons p{
       font-size: 18px;
       font-weight: 300;
       color: var(--white);
}
.buttons span {
    font-size: 18px;
    font-weight: 100;
    color: var(--white);
}

.titles{
    font-weight: 300;
    font-size: 18px;
    color: var(--blue);
}

/* анимации */

.slimeright-enter-active,
.slimeright-leave-active {
  transition: all 50ms ease;
}
.slimeright-enter-from,
.slimeright-leave-to {
  transform: translateX(100%);
}

.slimetop-enter-active,
.slimetop-leave-active {
  transition: all 200ms ease;
}
.slimetop-enter-from,
.slimetop-leave-to
{
  transform: translateY(-100%);
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.2s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.nocopy{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.centeproduct{
  text-align: center;
  margin: 20px 0px;
}

.cuscheck{
    display: grid;
    align-items: center;
    justify-items: center;
    width: 20px;
    height: 20px;
}

.cuscheck input[type='checkbox']:checked {
  background-color: var(--black);
}
.cuscheck input[type='checkbox']:checked:after {
    content: '\2713';
    color: white;
    font-size: 30px;
    position: absolute;
    margin-top: -17px;
    margin-left: -10px;
}
.cuscheck input[type='checkbox']{
  text-align: center;
  appearance:none;
  border: 1px solid var(--black);
  box-shadow: none;
  margin-bottom: 0px;
}
.bold{
  font-weight: 300;
    color: var(--black);
    font-size: 18px;
}
.nodisplay{
  display: none;
}
.customCheckbox{
    border: 1px solid #C4C4C4;
    width: 70px;
    height: 30px;
    border-radius: 50px;
    background: #F3F5F7;
    overflow: hidden;
    transition: 500ms;
    margin: 5px 0px;
}

.customCheckbox:after{
    content: '';
    width: 30px;
    height: 30px;
    display: block;
    background: #A8AEB0;
    border-radius: 100%;
    transition: 500ms;
}

.customCheckboxActivity:after{
    background: var(--black);
    transform: translateX(40px);
}
</style>
