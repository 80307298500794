import axios from '@/utils/axios'
import { useCookies } from 'vue3-cookies'
import state from './state'
import { validPhones, notSet } from '../general'
import { ref, computed } from 'vue'

const url = `${process.env.VUE_APP_CRM_API_URL}`
const { client, activity, clientInfo, setUser, clearUser } = state()

const startProverka = ref(false)
const clientorder = ref(0)
const prenud5 = ref(false)

const viewUser = ref({
  view: false,
  module: 'dashbord',
  options: null
})

const oknoUser = (view, module = 'dashbord', options = null) => {
  if (client.value._id == '62fd6ad64886f195911e7395') { return }
  viewUser.value = {
    view: view,
    module: module,
    options: options
  }
}

const isBirthday = computed(() => {
  if (client.birthday !== null) {
    const mydate = () => new Date(client?.value?.birthday)
    const dr = [
      {
        d: mydate().getDate(),
        m: mydate().getMonth()
      },
      {
        d: new Date(mydate().setDate(mydate().getDate() + 1)).getDate(),
        m: new Date(mydate().setDate(mydate().getDate() + 3)).getMonth()
      },
      {
        d: new Date(mydate().setDate(mydate().getDate() + 2)).getDate(),
        m: new Date(mydate().setDate(mydate().getDate() + 3)).getMonth()
      },
      {
        d: new Date(mydate().setDate(mydate().getDate() + 3)).getDate(),
        m: new Date(mydate().setDate(mydate().getDate() + 3)).getMonth()
      },
      {
        d: new Date(mydate().setDate(mydate().getDate() - 1)).getDate(),
        m: new Date(mydate().setDate(mydate().getDate() - 3)).getMonth()
      },
      {
        d: new Date(mydate().setDate(mydate().getDate() - 2)).getDate(),
        m: new Date(mydate().setDate(mydate().getDate() - 3)).getMonth()
      },
      {
        d: new Date(mydate().setDate(mydate().getDate() - 3)).getDate(),
        m: new Date(mydate().setDate(mydate().getDate() - 3)).getMonth()
      }
    ]
    const nowdate = {
      d: new Date().getDate(),
      m: new Date().getMonth()
    }

    for (const one of dr) {
      if (one.d == nowdate.d && one.m == nowdate.m) {
        notSet('У клиента ДР, будет скидка 5%')
        return true
      }
    }
  }
  return false
})

const newclient = computed(() => {
  if (prenud5.value) {
    notSet('Применена скидка 5%')
    return true
  }
  if (startProverka.value) {
    if (clientorder.value > 0) {
      return false
    } else {
      notSet('Новый клиент, будет скидка 5%')
      return true
    }
  }
})

const getClients = async (data, type) => {
  try {
    switch (type) {
      case 'phone':
        data = validPhones(data)
        break
      case 'familia':
        data = data
        break
    }
    const client = await axios.post(`${url}/arm/client/auth`, { data: data, type: type })
    switch (client.length) {
      case 0:
        return { type: 'newclient', valid: false, data: [] }
      case 1:
        return { type: 'oneclient', valid: true, data: client[0] }
      default:
        return { type: 'multiclient', value: false, data: client }
    }
  } catch (e) {
    console.log(e)
  }
}

const getClientsCallSip = async () => {
  try {
    const { data } = await axios.post(`${url}/sip/listcall`)
    const callClient = []
    if (data.length > 0) {
      for (const one of data) {
        const client = await axios.post(`${url}/crm/getcustomers`, { data: one })
        callClient.push(...client.customers)
      }
    }

    return callClient
  } catch (e) {
    console.log(e)
  }
}

const updateLogin = async (data, type) => {
  try {
    const client = await axios.post(`${url}/arm/client/login`, { type: type, data: data })
    setUser(client)
    await proverkaNewClient()
  } catch (e) {
    console.log(e)
  }
}

const proverkaNewClient = async () => {
  try {
    startProverka.value = false
    const order = await axios.post('https://api.ultimatum.store/order/getuserall', { user: client.value._id })
    clientorder.value = clientorder.value + order.length
    const ordercrm = await axios.get(`https://api.ultimatum.store/crm/vieworderclient/${client.value.phone}`)
    clientorder.value = clientorder.value + ordercrm.orders.length
    startProverka.value = true
  } catch (e) {
    startProverka.value = true
  }
}

const saveUser = async () => {
  try {
    const d = {
      _id: client.value._id,
      data: {
        firstname: client.value.firstname,
        lastname: client.value.lastname,
        birthday: client.value.birthday,
        email: { mail: client.value.email.mail },
        notes: client.value.notes
      },
      type: '$set'
    }

    await axios.post('https://api.ultimatum.store/shop/user/editors/', d)
  } catch (e) {

  }
}

export {
  getClients,
  updateLogin,
  client,
  clientInfo,
  activity,
  clearUser,
  getClientsCallSip,
  newclient,
  isBirthday,
  saveUser,
  oknoUser,
  viewUser,
  prenud5
}
